var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var generic = generic || {};
generic.productData = generic.productData || {};
var site = site || {};

prodcat.ui.getProductEl = function($el) {
  var $closestProdEl = $el.closest('.js-product');
  var prodId = $closestProdEl.attr('data-product-id');
  return $(".js-product[data-product-id='" + prodId + "']");
};

/*
 * prodcat.ui.getProductEl will break if there are multiple js-products like if
 * there is a product-grid containing the element. closestProductEl is mainly
 * for getting the proper $product when checking for feature flag toggles.
 */
prodcat.ui.getContextProductEl = function($el) {
  var $closestProdEl = $el.closest('.js-product');
  return $closestProdEl;
};

/*
 * Limit scope of js-product to elements current showing the sku.
 */
prodcat.ui.getSkuScopedProductEl = function(skuData) {
  var productId = skuData.PRODUCT_ID;
  var skuBaseId = skuData.SKU_BASE_ID;

  // Limit to the products current showing this sku.
  // We also select product elements without base-sku-id to support older style
  // product elements. Mostly for backwards compat.
  var productQuery = ".js-product[data-product-id='" + productId + "']";
  var queries = [
    productQuery + "[data-sku-base-id='" + skuBaseId + "']",
    productQuery + ':not([data-sku-base-id])',
    productQuery + "[data-sku-base-id='']",
  ];
  var query = queries.join(',');
  var $product = $(query);
  return $product;
};

/**
 * Adds SKUs to cart.
 */
prodcat.ui.addToCart = function(args, $addBtn) {
  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return null;
  }

  var quantity;
  if (typeof args.quantity !== 'undefined') {
    quantity = args.quantity;
  } else {
    quantity = 1;
  }

  $(document).trigger('addToCart.before', {skuBaseId: skuBaseId, quantity: quantity});
  $(document).trigger('addToCart.buttonStatus', [$addBtn, 'pending']);

  var catBaseId = '';
  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
    if (matchResult) {
      catBaseId = matchResult[0];
    }
  }

  args.skus = args.skus || (typeof skuBaseId === 'string' ? [skuBaseId] : skuBaseId);
  args.itemType = args.itemType || 'cart';
  args.QTY = args.QTY || quantity;

  // Conditionally add a CAT_BASE_ID key to the list of parameters to send
  var cbid = args.CAT_BASE_ID || catBaseId;
  if (cbid.length > 0) {
    args.CAT_BASE_ID = cbid;
  }

  generic.checkout.cart.updateCart({
    params: args,
    onSuccess: function(r) {
      var resultData = r.getData();
      var messages = r.getMessages();
      if (messages) {
        messages.forEach(function(msg) {
          if (msg.key === 'offer_criteria_not_met' && msg.tags.indexOf('one_time_purchase') > -1) {
            resultData.otpErrorMessage = msg.text;
          }
        });
      }
      $(document).trigger('addToCart.buttonStatus', [$addBtn, 'success']);
      $(document).trigger('addToCart.success', [resultData]);
      $('.js-cart_overlay').parent().removeClass('hidden').focus();
    },
    onFailure: function(ss) {
      var errorObjectsArray = ss.getMessages();
      $(document).trigger('addToCart.buttonStatus', [$addBtn, 'failure']);
      $(document).trigger('addToCart.failure', [errorObjectsArray]);
    }
  });
};

prodcat.ui.getCartMessage = function(cartResult, msg) {
  var prod = cartResult.getItem().product;
  var sku = prod.sku;
  var returnMsg = sku.SKU_ID + ' ' + prod.PROD_RGN_NAME;
  returnMsg += sku.SHADENAME ? ' ' + sku.SHADENAME : '';
  returnMsg += ' ' + msg;
  return returnMsg;
};

/**
 * Adds a SKU to the user's primary favorites list.
 * @param {Object} args
 * @param {Object} args.skuData a set of key-value pairs describing a SKU
 * @param {String, Number} args.skuData.SKU_BASE_ID Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 * @param {String} args.skuData.PARENT_CAT_ID Category ID for the SKU
 * @param {String, Number} args.skuBaseId Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 */
prodcat.ui.addToFavorites = function(args) {
  var params = {
    '_SUBMIT': 'alter_collection',
    'action': 'add'
  };

  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return null;
  }
  params.SKU_BASE_ID = skuBaseId;

  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
    if (matchResult) {
      params.CAT_BASE_ID = matchResult[0];
    }
  }

  generic.jsonrpc.fetch({
    method: 'rpc.form',
    params: [params],
    onSuccess: function(jsonRpcResponse) {
      var d = jsonRpcResponse.getData();
      var r = d.ac_results[0].result;
      var cr;

      if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
        cr = jsonRpcResponse.getCartResults();
        $(document).trigger('addToWishlist.exists', [cr]);
      } else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
        cr = jsonRpcResponse.getCartResults();
        $(document).trigger('addToWishlist.success', [cr]);
        if (!_.isUndefined(args.$el) && args.$el.length) {
          $('.icon-heart_outline', args.$el).removeClass('icon-heart_outline').addClass('icon-heart');
        }
      }
    },
    onFailure: function(jsonRpcResponse) {
      console.log('add to favorites failure');
      console.log(jsonRpcResponse.getError());
    }
  });
};

/**
 * Base class for functional UI widgets that are bound to product data.
 * The element passed as the parameter must include data-product-id and data-sku-id attributes.
 * @param {jQuery} $ele - the element to be bound to product data
 */
prodcat.ui.widget = function($ele) {
  var that = {};
  that.$ele = $ele;
  that.productId = that.$ele.attr('data-product-id');

  that.$ele.on('sku:select', function(e, skuBaseId) {
    $(this).attr('data-sku-base-id', skuBaseId);
    e.stopPropagation();
    $('.js-access-content-expanded').focus();
  });
  return that;
};

/*
 ***********************
 * Add-to-cart button
 ***********************
 */
prodcat.ui.addCartButton = {
  selectSku: function($addBtn, skuBaseId) {
    $addBtn.data('sku-base-id', skuBaseId);
    this.updateInvStatus($addBtn);
  },
  updateInvStatus: function($addBtn) {
    var skuBaseId = '';
    var skuDataL2 = $();
    var $select = $();
    var $targetAddBtn = $();

    $.each($addBtn, function (index, addBtn) {
      skuBaseId = $(addBtn).data('sku-base-id') + '';

      if (skuBaseId === null || skuBaseId === 'undefined' || skuBaseId === '') {
        return;
      }

      skuDataL2 = prodcat.data.getSku(skuBaseId);

      if (!skuDataL2) {
        return;
      }

      $select = $(addBtn).parents('.js-product');
      $targetAddBtn = $('.js-add-to-cart[data-product-id="' + skuDataL2.PRODUCT_ID + '"]', $select);

      if (skuDataL2 && !skuDataL2.isShoppable) {
        $targetAddBtn.addClass('button--disabled');
        $targetAddBtn.data('disabled', true);
      } else if (!$select.hasClass('js-product-dramming')) {
        $targetAddBtn.removeClass('button--disabled');
        $targetAddBtn.data('disabled', false);
      }
    });
  }
};

//this code needs to be triggered first
$(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
  if (typeof skuBaseId === 'undefined') {
    skuBaseId = prodcat.ui.getSkuIdByProduct($(this));
  }
  if (Drupal.settings.bb_product.display_sku_alt_image) {
    var $product = $(this);
    var $gallery = $('.js-product-gallery', $product);
    if ($gallery.length < 1) {
      return null;
    }
    Drupal.behaviors.productGalleryV1.displaySkuAltImage($gallery, skuBaseId, 'url_hit');
  }
});

//function can be removed?!?!

$(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
  var $addBtn = $('.js-add-to-cart', $(this));
  if ($addBtn.length < 1) {
    return null;
  }
  if (typeof skuBaseId === 'undefined') {
    skuBaseId = prodcat.ui.getSkuIdByProduct($(this));
  }
  prodcat.ui.addCartButton.selectSku($addBtn, skuBaseId);
});

$(document).on('inv_status_data:updated', '.js-product', function() {
  var $addBtn = $('.js-add-to-cart', $(this));
  if ($addBtn.length < 1) {
    return null;
  }
  prodcat.ui.addCartButton.updateInvStatus($addBtn);
});

$(document).on('product.init', '.js-product', function(e) {
  var $addBtn = $('.js-add-to-cart', $(this));
  if ($addBtn.length < 1) {
    return null;
  }
  prodcat.ui.addCartButton.updateInvStatus($addBtn);
});

$(document).on('click', '.js-add-to-cart, .js-kit-add-to-cart', function(e) {
  e.preventDefault();
  var $addBtn = $(this);
  var $product = $addBtn.closest('.js-product-full');
  var $nonShadeItem = $product.find('.js-product-starter-non-shaded-item');
  var arrayKitSkus;

  if ($addBtn.data('disabled')) {
    return null;
  }

  var kitSkus = $addBtn.data('kit-skus');

  if ($nonShadeItem.length > 0) {
    arrayKitSkus = kitSkus.split(',');
    if (kitSkus.slice(-1) === ',') {
      arrayKitSkus.pop();
    }
    $nonShadeItem.each(function() {
      var $item = $(this);
      if ($item.length > 0) {
        var skuItemId = $item.data('skuBaseId');
        arrayKitSkus.push(skuItemId);
      }
    });
    kitSkus = arrayKitSkus.join(',');
  }

  var skuBaseId = $addBtn.data('sku-base-id');
  if ((!skuBaseId || skuBaseId.length < 1) && !kitSkus) {
    return null;
  }

  // Account for adding multiple skus to bag
  var args = {};
  skuBaseId = !$addBtn.hasClass('js-kit-add-to-cart') ? String(skuBaseId) : String(kitSkus);
  if (skuBaseId.indexOf(',') >= 0) {
    args.skuBaseId = skuBaseId.split(',');
    if (skuBaseId.slice(-1) == ',') {
      args.skuBaseId.pop();
    }
  } else {
    args.skuBaseId = skuBaseId;
  }

  var quantity = $addBtn.attr('data-qty');
  if (!!quantity) {
    args.quantity = quantity;
  } else {
    args.INCREMENT = 1;
  }

  // Replenishment updates when sku is refillable and enable_replenishment=true in config.
  //  (currently applicable only when adding one item at a time)
  // Skip for Dramming product as it allow more than one SkU at a time
  var kitType = $addBtn.data('kit-type');
  var transKitType = site.translations.product.spp_dramming_kit_type;
  var kitPatt = new RegExp(transKitType, 'g');
  var skuDataL2 = prodcat.data.getSku(args.skuBaseId);

  if (skuDataL2 && _.isString(args.skuBaseId) && Drupal.settings.product_display && Drupal.settings.product_display.has_replenishment && !kitPatt.test(kitType)) {
    if (skuDataL2.REFILLABLE) {
      var frequency = $addBtn.data('replenishment');
      if (!!frequency) {
        args.REPLENISHMENT_FREQ = frequency;
      } else {
        args.REPLENISHMENT_FREQ = 0;
      }
      args.action = 'add';
      args.itemType = 'replenishment';
      args.add_to_cart = 1;
    }
  }

  var kitName = $addBtn.data('kit-name');
  if (!!kitName) {
    kitName = kitName + ' ';
  } else {
    kitName = Drupal.settings.default_kit_name;
  }

  var isKit = !!kitSkus && !$addBtn.hasClass('js-kit-add-to-cart') ? true : false;

  if (isKit) {
    args.COLLECTION_TYPE = 'UKIT';
    args.CAT_BASE_ID = $addBtn.data('kit-cat');
    args.COLLECTION_SUBTYPE = kitType;
    args.COLLECTION_NAME = kitName + Math.floor((Math.random() * 10000));
    args.SKU_BASE_ID = String(kitSkus).split(',');
    args.QTY = 1;
    args.action = 'add,edit,create_kit';
    args._SUBMIT = 'alter_collection';
    args.HAS_MULTIPLE_SKUS = 1;

    // per jim should not have skuBaseId
    delete args.skuBaseId;

    $(document).trigger('addToCart.buttonStatus', [$addBtn, 'pending']);

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [args],
      onSuccess: function(r) {
        var resultData = r.getData();
        var messages = r.getMessages();
        if (messages) {
          messages.forEach(function(msg) {
            if (msg.key === 'offer_criteria_not_met' && msg.tags.indexOf('one_time_purchase') > -1) {
              resultData.otpErrorMessage = msg.text;
            }
          });
        }
        $(document).trigger('addToCart.buttonStatus', [$addBtn, 'success']);
        $(document).trigger('addToCart.success', [resultData]);
        $('.js-cart_overlay').parent().removeClass('hidden').focus();
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessagesError();
        $(document).trigger('addToCart.buttonStatus', [$addBtn, 'failure']);
        $(document).trigger('addToCart.failure', [errorObjectsArray]);
      }
    });

    return;
  }

  prodcat.ui.addToCart(args, $addBtn);
  $('.js-cart_overlay').removeClass('hidden').focus();
  $('.js-add-to-cart').focusout(function() {
    if (!$('.js-cart_overlay').parent().hasClass('hidden')) {
      $('.js-cart_overlay').focus();
    }
  });
});

/*
 ***********************
 * Add-to-favorites button
 ***********************
 */

$(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
  var $addFavBtn = $('.js-add-to-favorites', $(this));
  if ($addFavBtn.length < 1) {
    return null;
  }
  if (typeof skuBaseId === 'undefined') {
    skuBaseId = prodcat.ui.getSkuIdByProduct($(this));
  }
  prodcat.ui.addFavoritesButton.selectSku($addFavBtn, skuBaseId);
});

$('.js-add-to-favorites').off('keydown').on('keydown', function(e) {
  if (site.getKeycode(e) === 13) {
    $(this).trigger('click');
  }
});

$(document).on('click', '.js-add-to-favorites', function(e) {
  e.preventDefault();
  $(this).addClass('js-return-focus');
  $('.js-product-cta').attr('aria-hidden', 'true').addClass('js-remove-hidden');
  var skuBaseId = $(this).data('sku-base-id');
  prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $(this)});
});

prodcat.ui.addFavoritesButton = {
  selectSku: function($addFavBtn, skuBaseId) {
    $addFavBtn.data('sku-base-id', skuBaseId);
  }
};

/*
 * Util to generate the shade path from js.
 */
prodcat.ui.generateShadePath = function(skuBaseId) {
  var skuData = prodcat.data.getSku(skuBaseId);
  var shadeName = _.result(skuData, 'SHADENAME');

  // include reserved characters missing from encodeURIComponent()
  function _fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  if (!!shadeName) {
    // removing $ sign to resolve jQuery 'unrecognized expression' errors
    var shadeNameString = shadeName.replace(/\$/, '');
    // removing shade numbers (skin foundation shades)
    var shadeNameNoShadenumber = shadeNameString.replace(/[0-9.()\ ]+$/g, '');
    var shadeNameNoSpaces = _fixedEncodeURIComponent(shadeNameNoShadenumber.toString().split(' ').join('_'));

    // class that excludes quickshops
    var shadePath = '#\/shade\/' + shadeNameNoSpaces;
    return shadePath;
  }
};

/*
 ***********************
 * Shade Router
 ***********************
 */
$(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
  if (typeof skuBaseId === 'undefined') {
    skuBaseId = prodcat.ui.getSkuIdByProduct($(this));
  }
  if (!$(this).closest('.js-quickshop-container').length) {
    // don't need to update ShadeRoute from QS panel
    $(this).trigger('product.updateShadeRoute', [skuBaseId]);
  }
});

$(document).on('product.updateShadeRoute', '.js-product', function(e, skuBaseId, shadeName) {
  var $product = $(this);
  var skuData = prodcat.data.getSku(skuBaseId);
  var shadeName = _.result(skuData, 'SHADENAME');

  // include reserved characters missing from encodeURIComponent()
  function _fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  if (!!shadeName) {
    // removing $ sign to resolve jQuery 'unrecognized expression' errors
    var shadeNameString = shadeName.replace(/\$/, '');
    // removing shade numbers (skin foundation shades)
    var shadeNameNoShadenumber = shadeNameString.replace(/[0-9.()\ ]+$/g, '');
    var shadeNameNoSpaces = _fixedEncodeURIComponent(shadeNameNoShadenumber.toString().split(' ').join('_'));
    // class that excludes quickshops
    var shadePath = '#\/shade\/' + shadeNameNoSpaces;
    // new for 2019 redesign
    var shadeRouteName = prodcat.ui.generateShadePath(skuBaseId);
    if ($product.hasClass('js-product-full-v2') || $product.hasClass('js-product-full-v3')) {
      history.replaceState({}, shadeName, shadeRouteName);
    }
    if ($product.hasClass('full')) {
      history.replaceState({}, shadeName, shadePath);
    } else if ($product.closest('.js-quickshop-container').length > 0) {
      $product.find('.js-quickshop-smoosh').data('shade-path', shadePath);
    }
  }
});

/*
 ***********************
 * display text fields (price, shade name, etc)
 ***********************
 */
prodcat.ui.textFields = [
  { selector: 'js-product-size', field: 'PRODUCT_SIZE' },
  { selector: 'js-product-unit-price', field: 'formattedUnitPrice' },
  { selector: 'js-product-price .price', field: 'formattedPrice' },
  {
    selector: 'js-product-price .taxedPrice',
    field: 'formattedTaxedPrice'
  },
  { selector: 'js-product-price .js-old_price', field: 'formattedPrice2' },
  { selector: 'js-sku-shade-name', field: 'SHADENAME' },
  { selector: 'js-sku-shade-description', field: 'SHADE_DESCRIPTION' },
  { selector: 'js-sku-misc-flag-text', field: 'MISC_FLAG_TEXT' },
  {
    selector: 'js-after_installment_price',
    field: 'formattedInstallmentPrice'
  },
  {
    selector: 'js-sku-expiration-date',
    field: 'EXPIRATION_DATE'
  }
];

$(document).on('product.skuSelect', '.js-product', function(e, skuBaseId, hasDramming) {
  hasDramming = hasDramming || false;
  if (typeof skuBaseId === 'undefined') {
    skuBaseId = prodcat.ui.getSkuIdByProduct($(this));
  }
  prodcat.ui.updateTextFields($(this), skuBaseId, hasDramming);
});

$(document).on('product.skuSelectKit', '.js-product', function(e, skuBaseId, hasDramming, $shadesGridParent) {
  hasDramming = hasDramming || false;
  if (typeof skuBaseId === 'undefined') {
    skuBaseId = prodcat.ui.getSkuIdByProduct($(this));
  }
  prodcat.ui.updateTextFieldsKit($(this), skuBaseId, hasDramming, $shadesGridParent);
});

$(document).on('product.skuDisplay', '.js-product', function(e, skuBaseId) {
  if (typeof skuBaseId === 'undefined') {
    skuBaseId = prodcat.ui.getSkuIdByProduct($(this));
  }
  prodcat.ui.updateTextFields($(this), skuBaseId);
});

prodcat.ui.getSkuIdByProduct = function($el) {
  var productId = $el.data('productId');
  var productData = prodcat.data.getProduct(productId);
  var skuBaseId;
  if (!!productData && !!productData.defaultSku) {
    skuBaseId = productData.defaultSku.SKU_BASE_ID;
  } else if (!!productData && !!productData.skus) {
    skuBaseId = productData.skus[0].SKU_BASE_ID;
  }
  return skuBaseId;
};

prodcat.ui.updateTextFields = function($productEl, skuBaseId, hasDramming) {
  var skuData = prodcat.data.getSku(skuBaseId);

  if (skuData === null) {
    return;
  }

  _.each(prodcat.ui.textFields, function(el) {
    var $el = $('.' + el['selector'], $productEl);
    var productId = skuData['PRODUCT_ID'];
    var $priceElemsWrapper = $productEl.find('.js-old_price').closest('[data-product-id="'+productId+'"]:not(.js-product-full)').parent();

    if ($el.length === 0) {
      return null;
    }
    // as no shade selected, emptying the SHADENAME & SHADE_DESCRIPTION
    if (hasDramming === 'noshade') {
      skuData['SHADENAME'] = '';
      skuData['SHADE_DESCRIPTION'] = '';
    }
    if (($productEl.hasClass('js-product-dramming') && el.field === 'formattedPrice')) {
      return null;
    }
    if ($productEl.find('.js-size-picker').length > 0) {
      // If current element has a size picker, filter out elements inside picker.
      // Keep the rest and make the update.
      $el = $el.filter(function () {
        return $(this).closest('.js-size-picker').length < 1;
      });
    }
    if ($el.length < 1) {
      // Return if we no longer have elements to change after filter
      return null;
    }
    if (el.field === 'formattedPrice2' && typeof skuData['PRICE2'] !== 'number') {
      // Only remove discount_price class from the main product's price related items on product full
      $productEl.hasClass('js-product-grid-item') ?
      $productEl.find('.js-old_price').parent().removeClass('discount_price') :
      $priceElemsWrapper.find('.js-old_price').parent().removeClass('discount_price');
      skuData['formattedPrice2'] = '';
    } else if (el.field === 'formattedPrice2' && typeof skuData['PRICE2'] === 'number') {
      // Added to check the invalid PRICE2 value (APACOPS-1026)
      if (skuData['PRICE2'] > skuData['PRICE']) {
        // Only add discount_price class to main product's price related items on product full
        $productEl.hasClass('js-product-grid-item') ?
        $productEl.find('.js-old_price').parent().addClass('discount_price') :
        $priceElemsWrapper.find('.js-old_price').parent().addClass('discount_price');
      } else {
        skuData['formattedPrice2'] = '';
        $productEl.find('.js-old_price').parent().removeClass('discount_price');
      }
    }

    if (el['selector'] === 'js-product-price' && Drupal.settings.product_display.display_tax_pricerange) {
      var postTaxLabel = '&#31246;&#36796;';
      $el.html(postTaxLabel + skuData.formattedTaxedPrice);
    } else {
      $el.html(skuData[el.field]);
      // when updating the price, remove discount_price class from skus that are not discounted 
      if (
        el.field === 'formattedPrice2' &&
        !skuData[el.field]?.length &&
        $el.siblings('.js-new_price').length > 0
      ) {
        $el.siblings('.js-new_price').parent().removeClass('discount_price');
      }
      if (skuData[el.field] != null && skuData[el.field].length > 0 && $el.hasClass('keep-hidden-if-empty')) {
        $el.removeClass('hidden');
      } else if ($el.hasClass('keep-hidden-if-empty')) {
        $el.addClass('hidden');
      }
      if (el.field === 'EXPIRATION_DATE') {
        if (skuData['EXPIRATION_DATE']) {
          $el.html(site.translations.product.expiration_date_label + ': ' + skuData['EXPIRATION_DATE']);
          $el?.siblings('.js-sku-use-by')?.removeClass('hidden');
        } else {
          $el?.siblings('.js-sku-use-by')?.addClass('hidden');
        }
      }
    }
  });
};

prodcat.ui.updateTextFieldsKit = function($productEl, skuBaseId, hasDramming, $shadesGrid) {
  var skuData = prodcat.data.getSku(skuBaseId);
  if (skuData === null) {
    return;
  }

  var $starterKitItem = $shadesGrid.closest('.js-product-starter-item');

  _.each(prodcat.ui.textFields, function(el) {
    var $el = $('.' + el['selector'], $starterKitItem);

    if ($el.length === 0) {
      return null;
    }
    // as no shade selected, emptying the SHADENAME & SHADE_DESCRIPTION
    if (hasDramming === 'noshade') {
      skuData['SHADENAME'] = '';
      skuData['SHADE_DESCRIPTION'] = '';
    }
    if ($productEl.hasClass('js-product-dramming') && el.field === 'formattedPrice') {
      return null;
    }
    if (el.field === 'formattedPrice2' && typeof skuData['PRICE2'] !== 'number') {
      skuData['formattedPrice2'] = '';
      $productEl.find('.js-old_price').parent().removeClass('discount_price');
    } else if (el.field === 'formattedPrice2' && typeof skuData['PRICE2'] === 'number') {
      // Added to check the invalid PRICE2 value (APACOPS-1026)
      if (skuData['PRICE2'] > skuData['PRICE']) {
        $productEl.find('.js-old_price').parent().addClass('discount_price');
      } else {
        skuData['formattedPrice2'] = '';
        $productEl.find('.js-old_price').parent().removeClass('discount_price');
      }
    }

    if (el['selector'] === 'js-product-price' && Drupal.settings.product_display.display_tax_pricerange) {
      var postTaxLabel = '&#31246;&#36796;';
      $el.html(postTaxLabel + skuData.formattedTaxedPrice);
    } else {
      $el.html(skuData[el.field]);
      if (skuData[el.field] != null && skuData[el.field].length > 0 && $el.hasClass('keep-hidden-if-empty')) {
        $el.removeClass('hidden');
      } else if ($el.hasClass('keep-hidden-if-empty')) {
        $el.addClass('hidden');
      }
    }
  });
};

//makeup workshops link added attributes
$('.js_directions').focusin(function() {
  var $workshopDetails = $(this).parent().find('.js_makeup_workshops_details_title').attr('id');
  $(this).parents('.js_makeup_workshops_location_details').find('.js_directions > a').attr('aria-describedby', $workshopDetails);
});

/**
 * Takes a product ID & SKU Base ID as parameters. Fires a "sku:select" event on all
 * elements whose data-product-id attribute has a value that matches the product ID.
 * Passes the SKU Base ID as a memo to that event.
 * @param {string} productId
 * @param {string} skuBaseId
 */
prodcat.ui.selectSku = function(productId, skuBaseId) {
  var prodSlctr = "[data-product-id='" + productId + "']";
  $(prodSlctr).trigger('sku:select', skuBaseId);
};

/**
 * Takes a product ID & SKU Base ID as parameters. Fires a "sku:select" event on all
 * elements whose data-product-id attribute has a value that matches the product ID.
 * Passes the SKU Base ID as a memo to that event.
 * @param {string} productId
 * @param {string} skuBaseId
 */
prodcat.ui.displaySku = function(productId, skuBaseId) {
  var prodSlctr = "[data-product-id='" + productId + "']";
  $(prodSlctr).trigger('sku:display', skuBaseId);
};

prodcat.ui.quickshopLink = function($ele) {
  var that = prodcat.ui.widget($ele);
  var data = prodcat.data.getProduct(that.productId);

  if (_.isObject(data)) {
    data.is_quickshop = true;

    that.$ele.on('click', function(e) {
      e.preventDefault();
      // Hide QS link
      $('.hideqs').removeClass('hideqs');
      $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true').addClass('hideqs');
      var args = {
        $ele: $(this),
        product: data
      };
      if ($(this).hasClass('js-quickshop-link--v2')) {
        $(document).trigger('product:quickshopV2', args);
      } else {
        $(document).trigger('product:quickshop', args);
        $('.js-quickshop__container').focus();
      }
      $('.js-quickshop').attr('aria-expanded', $('.js-quickshop').attr('aria-expanded') === 'true' ? 'false' : 'true');
      site.restrict_navigation($('.js-quickshop-container'));

      // Note: Triggers the keydown event for Favourites, Shades and Add to bag when Quickshop windown launches as not getting JSON response for existing Keydown event
      $('.js-swatch-list-item-link').on('keydown', function(e) {
        if (site.getKeycode(e) === 13) {
          $(this).trigger('click');
        }
      });
      $('.js-add-to-cart').off('keydown').on('keydown', function(e) {
        if (site.getKeycode(e) === 13) {
          $(this).trigger('click');
        }
      });
      $('.js-add-to-favorites').off('keydown').on('keydown', function(e) {
        if (site.getKeycode(e) === 13) {
          $(this).trigger('click');
        }
      });
      $('.js-product__promo').find('a').on('keydown', function(e) {
        if (site.getKeycode(e) === 9) {
          e.preventDefault();
          if (e.shiftKey) {
            $('.js-add-to-cart').focus();
          } else {
            $('.js-quickshop-close').focus();
          }
        }
      });
    });

    that.$ele.on('keydown', function(e) {
      if (site.getKeycode(e) === 13) {
        $(this).trigger('click');
      }
    });
  }
};

/**
 * Creates product data widgets by instantiating instances of
 * prodcat UI classes and attaching them to HTML elements. To prevent
 * redundant events, the method flags elements once they are bound.
 */
prodcat.ui.bind = function(selector, uiClass, context) {
  var flaggedClassName = 'js-prodcat-ui-attached';

  $(selector, context).not('.' + flaggedClassName).each(function() {
    var $ele = $(this);
    uiClass($ele);
    $ele.addClass(flaggedClassName);
  });
};

/**
 * Attaches events to add-to-cart buttons, sku menus, inventory status lists.
 * It is called on page load. If elements are added to DOM after page load,
 * call prodcat.ui.init() to attach the events.
 */
prodcat.ui.init = function(context) {
  prodcat.ui.bind('.js-quickshop-link, .js-quickshop-link--v2', prodcat.ui.quickshopLink, context);
  Drupal.behaviors.selectBox.attach(context);
  $('.responsive-tabs--powerreviews').easyResponsiveTabs();

  // Shade Router
  var changeSppShade = function(shadeName) {
    $('.show_select:visible').trigger('click');
    var $product = $('.product--full, .js-product-full-v2, .js-product-full-v3, .js-product-full-v4, .js-starter-kit-v1', context);
    var shadeNameNoSpaces = decodeURIComponent(shadeName.toString().split('_').join(' ').toLowerCase());
    var prodData = prodcat.data.getProduct($product.data('product-id'));

    _.each(prodData.skus, function(sku) {
      var skuName = sku.SHADENAME;
      var skuNameNoShadenumber = skuName.replace(/[0-9.()\ ]+$/g, '').toLowerCase();
      var skuBaseId = sku.SKU_BASE_ID;
      var productId = sku.PRODUCT_ID;

      if (shadeNameNoSpaces === skuNameNoShadenumber) {
        skuBaseId = sku.SKU_BASE_ID;
        productId = sku.PRODUCT_ID;
        if ($product.hasClass('js-product-dramming')) {
          $product.trigger('product.skuSelect', [skuBaseId, 'noshade']);
        } else if (!$product.hasClass('js-starter-kit-v1')) {
          $product.trigger('product.skuSelect', skuBaseId);
        }

        // On clicking add to bag from try on email, slick slider needs small delay to
        // display required smoosh based on deep linking
        if ($('body', context).hasClass('device-mobile')) {
          setTimeout(function() {
            $('.js-product-gallery .js-product-gallery__div-thumb--smoosh', context).trigger('click');
          }, 100);
        }
      }
    });
  }; // changeSppShade

  var changeSppFamily = function(family) {
    var $product = $('.product-full, .js-product-full-v2, .js-product-full-v3', context);
    $product.trigger('product.showColorFamily', [family]);

    $(document).on('product.colorFamilyLoaded', function() {
      $product.trigger('product.showColorFamily', [family]);
    });
  };

  // SPP only
  if ($('.spp, .spp-mobile-page, .js-spp-product-layout-v2, .js-spp-product-layout-v3', context).length > 0) {
    // route accordingly
    var routes = {
      //'\/shade\/:shadeName': changeSppShade
      '\/shade\/:shadeName\/?((\w|.)*)': changeSppShade,
      'family/:family:': changeSppFamily
    };
    // initialize director js lib
    var router = Router(routes);
    router.init();
  }

  // hijack back button instead of going through every shade that was selected
  if (document.referrer.length > 0) {
    // Necessary hack because WebKit fires a popstate event on window load
    // https://code.google.com/p/chromium/issues/detail?id=63040
    // https://bugs.webkit.org/process_bug.cgi
    window.addEventListener('load', function() {
      setTimeout(function() {
        window.addEventListener('popstate', function(e) {
          // send the user back to the referrer when the user has been clicking shades on SPP and wants to go back
          if (e.target.location.hash.match(/^#\/shade/)) {
            window.location = document.referrer;
          }
        });
      }, 0);
    });
  }

  // Pre-order Tab condition check
  if (Drupal.settings.bb_product.pre_order_tabs) {
    var $product = $(context).find('div.js-product');
    var prodData = prodcat.data.getProduct($product.data('product-id'));
    var $tabs = $('.js-product__sku-categories', $product);
    if ($tabs.length < 1) {
      return null;
    }
    prodcat.ui.preOrderTabBuilder(prodData);
  }
};

/**
 * Wire up behavior on page-load according to js- classnames.
 */
Drupal.behaviors.prodcatUiInit = {
  attach: function(context) {
    var $product = $('.js-product', context);
    $(context).trigger('products.init-start');
    prodcat.ui.init(context);
    $product.trigger('product.init');
    $(context).trigger('products.init-finish');
    var skuBaseId = $product.data('sku-base-id');
    $('.js-product-grid-item-promo').removeAttr('tabindex');
  }
};
